import React from 'react';
import { call, put } from 'redux-saga/effects';
import { TestStepStates, buildMapStateToProps, sleep } from './core';
import { testStatusUpdate } from '../store/actions';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';
import ResultRow from '../components/automatedTests/ResultRow';
import { BadBrowser, BadOS, DeviceIsMobile } from '../components/automatedTests/resultsSummaries';
import { useTranslation } from 'react-i18next';
import Bowser from 'bowser';

/**
 * Checks that the OS and Browser type are supported, and that the versions are in the supported range.
 * @module testModules:browserVersion
 */

export const TEST_ID = 'SoftwareVersions';

/**
 * Test execution saga for the Browser Version test.
 * Specs are built using third-party tools:
 * * For valid Operating System strings, see [the detect-browser GitHub repo](https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts).
 * * For information on the supportedBrowsers spec, see [Bowser's documentation](https://lancedikson.github.io/bowser/docs/index.html).
 * @param {Object} config Object containing configuration parameters.
 * @param {Array} supportedOperatingSystems Array of strings containing valid operating systems. 
 * @param {Object} supportedBrowsers Object definition (as per Bowser) to validate browser support.
 */
export function *runTest ({supportedOperatingSystems, supportedBrowsers, mobileAllowed}) {
    yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null));
    const browser = detect();
    let passed = false;
    let reason = null;
    
    const isMobileDevice = () => navigator.userAgent.match(/mobile/i) && !navigator.userAgent.match(/iPad/i);
    
    const isSupportedOs = supportedOperatingSystems.some(e => e === browser.os);
    const isSupportedBrowser = Bowser.getParser(window.navigator.userAgent).satisfies(supportedBrowsers);
    const isMobileWhenDisallowed = isMobileDevice() && !mobileAllowed;
    if (!isSupportedOs) {
        reason = 'os';
    } else if (!isSupportedBrowser) {
        reason = 'browser';
    } else if (isMobileWhenDisallowed) {
        reason = 'deviceIsMobile';
    } else {
        // We've passed all relevant checks here
        passed = true;
    }

    const result = {
        browserName: browser.name,
        browserVersion: browser.version,
        reason: reason,
        os: browser.os
    };
    yield call(sleep, 500); // For looks
    yield put(testStatusUpdate(TEST_ID, passed ? TestStepStates.Passed : TestStepStates.Failed, result));
}

const BrowserVersionResultRow = ({status, result, progressPercentage, productType}) => {
    const { t } = useTranslation();
    let browserVersionError = null;
    if (result?.reason === 'browser') {
        browserVersionError = <BadBrowser key="badBrowser" productType={productType} />;
    } else if (result?.reason === 'deviceIsMobile') {
        browserVersionError = <DeviceIsMobile key="badBrowser" productType={productType} />;
    } else {
        browserVersionError = <BadOS key="badOS" />;
    }
    return <ResultRow status={status} title={t('automaticTests.results.browserVersion.title')} subtitle={t('automaticTests.results.browserVersion.subtitle')} errorMessage={browserVersionError} progressPercentage={progressPercentage} />;
};

const mapStateToProps = buildMapStateToProps(TEST_ID);

export const BrowserVersionResultRowContainer = connect(mapStateToProps)(BrowserVersionResultRow);
