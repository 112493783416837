import React from 'react';
import { Typography, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

/** @module components:Success */

const useStyles = makeStyles((theme) => {
    return {
        successPaper: {
            marginTop: 72,
            padding: 32,
            paddingTop: 64,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        successCircle: {
            width: 128,
            height: 128,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: -130,
            marginBottom: 16
        },
        successIcon: {
            width: '100%',
            height: '100%',
            color: theme.palette.success.main
        },
        successButton: {
            background: theme.palette.success.dark,
            color: theme.palette.getContrastText(theme.palette.success.dark),
            '&:hover': {
                background: theme.palette.success.main
            }
        }
    };
});

/**
 * Generic "Success" view - shows a large icon and some suitable text to indicate the prior step succeeded. Optionally
 * shows some additional components (eg: a "Next" button).
 * @param {Object} props
 * @param {MaterialUI:SvgIcon} props.IconComponent Component to use for the icon. Should be a material UI SvgIcon
 * @param {string} props.header Header text - should be pre-translated.
 * @param {string} props.body Body text - should be pre-translated.
 * @param {React:Component} [props.extra] An additional component to display below the body. Useful for "Next" type
 *                                        buttons.
 */
const Success = ({ IconComponent, header, body, extra }) => {
    const classes = useStyles();
    
    return <Paper className={classes.successPaper}>
        <Paper className={classes.successCircle} background="primary" elevation={3}>
            <IconComponent className={classes.successIcon} fontSize="large" />
        </Paper>
        <Typography variant="h4" paragraph>{header}</Typography>
        <Typography variant="body1" paragraph>{body}</Typography>
        {extra}
    </Paper>;
};


export default Success;
