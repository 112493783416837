import { call, select, put, take, fork } from 'redux-saga/effects';
import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import * as actions from '../store/actionsTypes';
import { sleep } from '../testModules/core';
import axios from 'axios';
import { eventChannel, END } from 'redux-saga';
import { API_BASE_URI } from '../Config';

/**
 * Endpoint URI for file uploads.
 */
export const UPLOAD_URI = `${API_BASE_URI}api/systemtest/uploadRecording`;

/**
 * Recording implemented based on local capture and encoding of a WebRTC stream. Has dependencies on the Twilio Video
 * API for track management.
 * 
 * @module sagas:localRecordingSaga
 */

/**
 * Singleton storage of the recording - avoiding storage of this in Redux.
 */
let RECORDING_BLOB = null;

/**
 * Redux store selector to get the current auth token.
 * @private
 */
export const getToken = (state) => {
    return state.resultSubmitReducer.token;
};

/**
 * Redux store selector to pull current audio and video streams from the store
 * @private
 */
const getStreams = (state) => {
    return {
        audio: state.videoTestReducer.audioStream,
        video: state.videoTestReducer.videoStream
    };
};

/**
 * Redux store selector to pull the location that the recording can be played back for.
 * Should be accessible before the Save process finishes (eg: made with URL.createObjectURL)
 * @private
 */
const getUri = (state) => {
    return state.videoTestReducer.recordingUri;
};

/**
 * Finds suitable devices and grabs streams using getUserMedia.
 * @private
 */
export async function _setupCaptureDevices() {
    const mediaDeviceConstraints = {
        facingMode: { ideal: 'user' },
        video: true,
        audio: true
    };

    const mediaStream = await navigator.mediaDevices.getUserMedia(mediaDeviceConstraints);

    const localVideoStream = mediaStream.getVideoTracks()[0];
    const localVideoTrack = new LocalVideoTrack(localVideoStream);
    
    const localAudioStream = mediaStream.getAudioTracks()[0];
    const localAudioTrack = new LocalAudioTrack(localAudioStream);

    return {
        videoTrack: localVideoTrack,
        videoStream: localVideoStream,
        audioTrack: localAudioTrack,
        audioStream: localAudioStream
    };
}

/**
 * Saga to search for and initalize local media devices, and build the relevant Twilio Tracks.
 */
export function *setupCaptureDevices () {
    const tracks = yield call(_setupCaptureDevices);
    yield put({
        type: actions.VIDEO_RECORDING_TRACKS_CREATED,
        payload: {
            videoTrack: tracks.videoTrack,
            audioTrack: tracks.audioTrack,
            videoStream: tracks.videoStream,
            audioStream: tracks.audioStream
        }
    });
}

/**
 * Stops the specified MediaRecorder and pulls the remaining data from it. Expected usage is on a MediaRecorder that
 * has had no `ondataavailable` specified, thus none of the data has been consumed.
 * @param {MediaRecorder} recorder MediaRecorder to stop and pull data from.
 * @returns {Blob}
 */
export async function stopAndFetch (recorder) {
    return new Promise((resolve, reject) => {
        try {
            recorder.ondataavailable = (e) => {
                resolve(e.data);
            };
            recorder.stop();
        } catch (e) {
            reject(e);
        }
    });
}

/**
 * Saga to clear down the current recording.
 */
export function *reset () {
    const uri = yield select(getUri);
    URL.revokeObjectURL(uri);
    RECORDING_BLOB = null;
    yield put({type: actions.VIDEO_RECORDING_TRACKS_RESET_DONE});
}

/**
 * Saga to start the recording process.
 */
export function *startRecording () {

    try {
        const streams = yield select(getStreams);
        const combinedStream = new MediaStream([streams.video, streams.audio]);

        var recorder;

        try {
            recorder = new MediaRecorder(combinedStream, {mimeType: 'video/webm'});
        }
        catch (err1) {
            try {
                // Fallback for iOS
                recorder = new MediaRecorder(combinedStream, {mimeType: 'video/mp4'});
            }
            catch (err2) {
                // If fallback doesn't work either log error
                console.error({err1});
                console.error({err2});
            }
        }

        recorder.start();
        yield put({type: actions.VIDEO_RECORDING_RECORDING_STARTED});
        
        const progressChannel = eventChannel(progressCallback => {
            const TIMER_LENGTH_MS = 15000;
            const TICK_INTERVAL_MS = 200;
            const ANIMATION_OFFSET_MS = 500; // HACK - offset the timer by a fixed amount to account for animation time.
            let i = 0;
        
            setTimeout(() => {
                clearInterval(ticker);
                progressCallback(1);
                progressCallback(END);
            }, TIMER_LENGTH_MS);

            const ticker = setInterval(() => {
                i++;
                progressCallback((i*TICK_INTERVAL_MS + ANIMATION_OFFSET_MS) / TIMER_LENGTH_MS);
            }, TICK_INTERVAL_MS);

            return () => clearInterval(ticker);
        });

        yield call(function * () {
            while (true) {
                // take(END) will cause the saga to terminate by jumping to the finally block
                const progress = yield take(progressChannel);
                yield put({type: actions.VIDEO_RECORDING_RECORD_PROGRESS, progress});
            }
        });

        const recordedData = yield call(stopAndFetch, recorder);
        yield call(sleep, 500);
        yield put({type: actions.VIDEO_RECORDING_RECORDING_STOPPED});

        RECORDING_BLOB = recordedData;

        yield put({type: actions.VIDEO_RECORDING_RECORDING_COMPLETED, uri: URL.createObjectURL(recordedData)});
    } catch (error) {
        // Throw an event down the stack
        console.error(error);
        yield put({type: actions.VIDEO_RECORDING_RECORDING_ERROR});
    }
}

/**
 * Async call to save the recording - emits events via the provided channel to show upload
 * progress.
 * @private
 */
export async function _saveRecording (token, emitProgress) {
    await axios.post(`${UPLOAD_URI}/${token}`, RECORDING_BLOB, {
        contentType: RECORDING_BLOB.contentType,
        onUploadProgress: function (progressEvent) {
            if (progressEvent.lengthComputable) {
                emitProgress(progressEvent.loaded / progressEvent.total);
            } else {
                emitProgress(null);
            }
        },
    });

    emitProgress(END);
}

/**
 * Saga for uploading the locally stored recording.
 * @generator
 * @yields {VIDEO_RECORDING_SAVE_COMPLETE} On success
 * @yields {VIDEO_RECORDING_SAVE_ERROR} On error
 */
export function *saveRecording () {
    const token = yield select(getToken);
    let emitProgress;
    const progressChannel = eventChannel(emitter => {
        emitProgress = emitter;
        return () => {};
    });

    // Mini-saga to track progress and emit Redux actions
    function *progressWatcher () {
        // Will be forcibly ended by Redux Sagas when the event channel is closed.
        while (true) {
            const progress = yield take(progressChannel);
            yield put({type: actions.VIDEO_RECORDING_SAVE_PROGRESS, progress});
        }
    }

    try {
        yield fork(progressWatcher, progressChannel);

        yield call(_saveRecording, token, emitProgress);
        RECORDING_BLOB = null;
        yield put({type: actions.VIDEO_RECORDING_SAVE_COMPLETE});
    } catch (e) {
        console.error(e);
        yield put({type: actions.VIDEO_RECORDING_SAVE_ERROR});
    }
}
