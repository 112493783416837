import { RecordingStates } from '../../store/reducers/videoTestReducer';
import React from 'react';
import {
    Button,
    Typography,
    Collapse,
    CircularProgress,
    Box,
    LinearProgress,
    ButtonGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Videocam, Send, Backspace, FiberManualRecord } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/** @module components:videoRecordingTest:PanelControls */

const useStyles = makeStyles({
    startRecording: {
        marginBottom: 32
    },
    '@keyframes blinker': {
        '0%': {opacity: 1},
        '25%': {opacity: 1},
        '50%': {opacity: 0},
        '75%': {opacity: 1},
        '100%': {opacity: 1}
    },
    blinking: {
        opacity: 1,
        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount:'infinite',
    }
});

/**
 * Component that fills the bottom panel of the recording test panel. Provides controls to step the recording process:
 * from recording, to preview, to saving.
 * @param {Object} props
 * @param {RecordingStates} props.status
 * @param {function} props.startRecording Callback to start the recording process
 * @param {function} props.reset Callback to reset the recording process
 * @param {function} props.saveRecording Callback to start saving the recording
 * @param {number} props.recordProgress Number (0-1) representing progress through the recording progress
 */
function PanelControls ({status, startRecording, reset, saveRecording, recordProgress}) {
    const classes = useStyles();
    const { t } = useTranslation();

    let showReady = false;
    let showBusy = false;
    let showRecording = false;
    let showCompleted = false;
    let showError = false;

    switch (status) {
        case RecordingStates.READY:
        case RecordingStates.INITIALIZING:
            showReady = true;
            break;
        case RecordingStates.STARTING:
        case RecordingStates.PROCESSING:
            showBusy = true;
            break;
        case RecordingStates.STARTED:
            showRecording = true;
            break;
        case RecordingStates.COMPLETED:
            showCompleted = true;
            break;
        case RecordingStates.ERROR:
        default:
            showError = true;
            break;
    }

    return <Box style={{minHeight: 300, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <Collapse in={showReady}>
            <Button
                startIcon={<Videocam />}
                variant="contained"
                color="secondary"
                className={classes.startRecording}
                onClick={() => {startRecording();}}
                disabled={status === RecordingStates.INITIALIZING}
            >
                {t('videoRecording.introduction.startButtonText')}
            </Button>
            <Typography variant="body1">
                {t('videoRecording.introduction.body')}
            </Typography>
        </Collapse>
        <Collapse in={showBusy}>
            <CircularProgress />
        </Collapse>
        <Collapse in={showRecording}>
            <FiberManualRecord color="secondary" fontSize="large" className={classes.blinking} />
            <Typography variant="body1" gutterBottom >
                {t('videoRecording.recordingInProgress.statusText')}
            </Typography>
            <LinearProgress value={100 * recordProgress} variant="determinate"></LinearProgress>
        </Collapse>
        <Collapse in={showCompleted}>
            <ButtonGroup>
                <Button startIcon={<Backspace />} variant="contained" className={classes.startRecording} onClick={() => {reset();}}>
                    {t('videoRecording.recordingCompleted.tryAgainButtonText')}
                </Button>
                <Button startIcon={<Send />} variant="contained" color="secondary" className={classes.startRecording} onClick={() => {saveRecording();}}>
                    {t('videoRecording.recordingCompleted.sendButtonText')}
                </Button>
            </ButtonGroup>
            <Typography>
                {t('videoRecording.recordingCompleted.explanationText')}
            </Typography>
        </Collapse>
        <Collapse in={showError}>
            <Button startIcon={<Backspace />} variant="contained" color="secondary" className={classes.startRecording} onClick={() => {startRecording();}}>
                {t('videoRecording.recordingError.tryAgainButtonText')}
            </Button>
            <Typography variant="body1">
                {t('videoRecording.recordingError.explanationText')}
            </Typography>
        </Collapse>
    </Box>;
}

PanelControls.propTypes = {
    status: PropTypes.string,
    startRecording: PropTypes.func,
    reset: PropTypes.func,
    saveRecording: PropTypes.func,
    recordProgress: PropTypes.number
};

export default PanelControls;
