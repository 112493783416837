import React from 'react';
import { call, put } from 'redux-saga/effects';
import { TestStepStates, buildMapStateToProps, sleep, exceptionToObject } from './core';
import { testStatusUpdate } from '../store/actions';
import { connect } from 'react-redux';
import ResultRow from '../components/automatedTests/ResultRow';
import { BadWebcam } from '../components/automatedTests/resultsSummaries';
import { useTranslation } from 'react-i18next';
export const TEST_ID = 'WebcamQuickTest';

/**
 * Basic video capture device test - ensures that a user's webcam is present, and that it can be attached to. Finds the
 * device, opens a stream, and captures (and discards) the stream for a short time.
 * @module testModules:videoCapture
 */

export function *runTest () {
    yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null));
    let status = TestStepStates.Failed;
    const result = {
        exception: null
    };
    const videoElement = document.createElement('video');

    // iOS Hack - see: https://github.com/webrtc/samples/issues/929
    videoElement.setAttribute('playsinline', true);

    try {
        const stream = yield call([navigator.mediaDevices, navigator.mediaDevices.getUserMedia], {video: true, audio: false});
        videoElement.srcObject = stream;
        yield call([videoElement, videoElement.play]);
        yield call(sleep, 1000);
        yield call([videoElement, videoElement.pause]);
        yield call(sleep, 1000);
        yield call(async () => {
            stream.getTracks().forEach(e => e.stop());
        });
        status = TestStepStates.Passed;
    } catch (e) {
        result.exception = exceptionToObject(e).name;
        console.error(result);
        yield call(sleep, 600); // For looks
    } finally {
        videoElement.remove();
    }
    yield put (testStatusUpdate(TEST_ID, status, result));
}

const VideoCaptureResultRow = ({status, result, progressPercentage}) => {
    const { t } = useTranslation();
    let error = '';

    if (status === TestStepStates.Failed) {
        const isMissing = result.videoCaptureTest?.exception.name === 'NotFoundError';
        const accessDenied = result.videoCaptureTest?.exception.name === 'NotAllowedError';
        const browser = result.browserVersion?.browserName;
        error = <BadWebcam isMissing={isMissing} accessDenied={accessDenied} browser={browser} key="badWebcam" />;
    }
    return <ResultRow status={status} title={t('automaticTests.results.videoCapture.title')} subtitle={t('automaticTests.results.videoCapture.subtitle')} errorMessage={error} progressPercentage={progressPercentage} />;
};

const mapStateToProps = buildMapStateToProps(TEST_ID);

export const VideoCaptureResultRowContainer = connect(mapStateToProps)(VideoCaptureResultRow);
