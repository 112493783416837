import React from 'react';
import { CircularProgress, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Done, Error } from '@mui/icons-material';
import { Card, Box, CardHeader, CardActionArea } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { TestStepStates, stateIsBad } from '../../testModules/core';

/**
 * @module components:automatedTests:ResultCategory
 */

const useStyles = makeStyles(theme => ({
    statusIcon: {
        width: 32,
        height: 32
    },
    baseCard: {
        minWidth: 200,
        padding: 0,
        marginBottom: 8
    },
    collapser: {
        borderTop: '1px #ddd solid'
    },
    expandIcon: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandIconOpen: {
        transform: 'rotate(180deg)'
    },
    expandoContainer: {
        paddingLeft: 0
    },
    expandIconContainer: {
        alignSelf: 'center',
        height: '1.5rem',
        margin: 0
    }
}));

/**
 * A Component designed to indicate a category containing several individual tests.
 * Contains several `ResultRow`s.
 * @param {Object} props
 * @param {string} props.title Short summary of what this category is (generally 1 or 2 words).
 * @param {string} props.pendingText Text to show whilst tests are running.
 * @param {string} props.successText Text to show when tests have completed successfully.
 * @param {string} props.errorText Text to show when tests have completed in a failed/error state.
 * @param {React.Component} props.children Child component to display inside this category. Sits inside a Material
 *                                         Collapse component, and may be unmounted.
 * @param {TestStepStates} props.status Current state to show for this category.
 * @param {boolean} props.autoExpand Whether or not to default to expanded - allows expansion on-click either way.
 */
const ResultCategory = ({ title, pendingText = null, successText = null, errorText = null, children, status, autoExpand }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);
    const toggleExpanded = () => setExpanded(!expanded);
    const [wasAutoExpand, setWasAutoExpand] = React.useState(status);
    React.useEffect(() => {
        if (autoExpand !== wasAutoExpand) {
            setWasAutoExpand(autoExpand);
            setExpanded(autoExpand && stateIsBad(status));
        }
    },
    [autoExpand, wasAutoExpand, status]);

    if (status === TestStepStates.Disabled) {
        return null;
    }
    
    const iconClasses = [classes.expandIcon];
    if (expanded) iconClasses.push(classes.expandIconOpen);
    
    let statusIcon = null;
    let bodyText = null;

    switch(status) {
        case TestStepStates.Passed:
            statusIcon = <Done className={classes.statusIcon} />;
            bodyText = successText;
            break;
        case TestStepStates.Error:
        case TestStepStates.Failed:
        case TestStepStates.Warning:
            statusIcon = <Error className={classes.statusIcon} color="error" />;
            bodyText = errorText;
            break;
        case TestStepStates.Running:
        case TestStepStates.Waiting:
        default:
            statusIcon = <CircularProgress size={32} />;
            bodyText = pendingText;
            break;
    }

    return <Card elevation={3} className={classes.baseCard}>
        <CardActionArea onClick={toggleExpanded}>
            <CardHeader
                title={title}
                subheader={bodyText}
                avatar={statusIcon}
                action={
                    <ArrowDownward fontSize="medium" htmlColor="#aaa" className={iconClasses.join(' ')} />
                }
                classes={{
                    action: classes.expandIconContainer
                }}
            />
        </CardActionArea>
        

        <Collapse in={expanded} timeout="auto" className={classes.collapser} unmountOnExit>
            <Box className={classes.expandoContainer}>
                { children }
            </Box>
        </Collapse>
    </Card>;
};

export default ResultCategory;
