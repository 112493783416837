import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const SUPPORTED_LANGUAGES = {
    ar: 'العربية',
    en: 'English',
    fr: 'français'
};

i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        backend: {
            loadPath: '/langs/{{lng}}.json?v=30a8bfad462d091ef8b85f2ecea2957c50468df4' // Substitution for cachebust done at deployment time
        },
        fallbackLng: 'en',
        keySeparator: '.',
        ns: ['common', 'errors'],
        defaultNS: 'common',
        whitelist: Object.keys(SUPPORTED_LANGUAGES),


        interpolation: {
            escapeValue: false // react already protects from xss
        }
    });

export default i18n;