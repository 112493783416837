import * as testSagas from './automatedTestSaga';
import { takeEvery } from 'redux-saga/effects';
import * as actions from '../store/actionsTypes';
import { runAuthentication } from './authSaga';
import * as localRecordingSaga from './localRecordingSaga';

export default function* rootSaga () {
    yield takeEvery(actions.RUN_AUTHENTICATION, runAuthentication);
    yield takeEvery(actions.RUN_ALL_TESTS, testSagas.runAllTests);
    yield takeEvery(actions.VIDEO_RECORDING_CREATE_TRACKS, localRecordingSaga.setupCaptureDevices);
    yield takeEvery(actions.VIDEO_RECORDING_START_RECORDING, localRecordingSaga.startRecording);
    yield takeEvery(actions.VIDEO_RECORDING_TRACKS_RESET, localRecordingSaga.reset);
    yield takeEvery(actions.VIDEO_RECORDING_SAVE_RECORDING, localRecordingSaga.saveRecording);
}
