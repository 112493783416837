import React from 'react';
import { call, put } from 'redux-saga/effects';
import { TestStepStates, buildMapStateToProps, sleep, exceptionToObject } from './core';
import { testStatusUpdate } from '../store/actions';
import { connect } from 'react-redux';
import ResultRow from '../components/automatedTests/ResultRow';
import { useTranslation } from 'react-i18next';
import { BadMicrophone } from '../components/automatedTests/resultsSummaries';

/**
 * Test to ensure that an audio capture device is available. Finds a suitable device, grabs a stream and attaches the
 * stream locally and ensures it can be played from.
 * @module testModules:audioCapture
 */

export const TEST_ID = 'MicrophoneQuickTest';

export function *runTest () {
    yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null));
    let status = TestStepStates.Failed;
    const result = {
        exception: null
    };
    const audioElement = document.createElement('audio');
    try {
        const stream = yield call([navigator.mediaDevices, navigator.mediaDevices.getUserMedia], {video: false, audio: true});
        audioElement.srcObject = stream;
        yield call([audioElement, audioElement.play]);
        yield call(sleep, 1000);
        yield call([audioElement, audioElement.pause]);
        yield call(sleep, 1000);
        yield call(async () => {
            stream.getTracks().forEach(e => e.stop());
        });
        status = TestStepStates.Passed;
    } catch (e) {
        result.exception = exceptionToObject(e).name;
        console.error(result);
        yield call(sleep, 600); // For looks
    } finally {
        audioElement.remove();
    }
    yield put (testStatusUpdate(TEST_ID, status, result));
}

const AudioCaptureResultRow = ({status, result, progressPercentage}) => {
    const { t } = useTranslation();
    
    const isMissing = result?.exception?.name === 'NotFoundError';
    const accessDenied = result?.exception?.name === 'NotAllowedError';
    const browser = result?.browserVersion?.browserName;
    const error = <BadMicrophone isMissing={isMissing} accessDenied={accessDenied} browser={browser} key="badMicrophone" />;

    return <ResultRow status={status} title={t('automaticTests.results.audioCapture.title')} subtitle={t('automaticTests.results.audioCapture.subtitle')} errorMessage={error} progressPercentage={progressPercentage} />;
};

const mapStateToProps = buildMapStateToProps(TEST_ID);

export const AudioCaptureResultRowContainer = connect(mapStateToProps)(AudioCaptureResultRow);
