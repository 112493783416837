import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import Success from '../Success';

/** @module components:automatedTests:VideoRecordingSuccess */


/**
 * View presented to users on successful completion of the automated tests stage.
 * Can optionally show a link to start the recording test.
 * @param {Object} props
 * @param {function} props.launchRecordingTest Callback to fire when the user wants to start the recording test.
 */
const VideoRecordingSuccess = () => {
    const { t } = useTranslation();
    
    return <Success
        IconComponent={CheckCircleOutlineOutlined}
        header={t('successPage.header')}
        body={t('successPage.body')}
    />;
};

export default VideoRecordingSuccess;
