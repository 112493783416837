import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Container, Collapse } from '@mui/material';
import { Skeleton } from '@mui/material';
import TestRunnerPanel from './automatedTests/TestRunnerPanel';
import * as actions from '../store/actionsTypes';
import { connect } from 'react-redux';
import VideoTestPanel from './videoRecordingTest/VideoTestPanel';

/** @module components:AppBody */

/**
 * Main component for application logic. Contains React Router routing logic to show the user the relevant parts of the
 * testing process. Application-level errors display here. Component-level errors may display in their own components.
 * @param {Object} props
 * @param {function} props.storeToken Redux callback to store the token. Fires a STORE_TOKEN event.
 * @param {ReactRouter:match} props.match React Router match - used to extract the auth token for this session.
 * @param {boolean} props.loaded Flag to indicate that the page is still loading. If true, content is replaced with a
 *                               suitable loading indicator.
 * @param {boolean} props.invalidToken Flag to indicate an invalid token was passed to the app. Shows a full-screen
 *                                     error if true.
 * @param {boolean} props.loadFailed Flag to indicate the server declined the token provided. Shows a full-screen error
 *                                   if true.
 * @param {boolean} props.loadError Flag to indicate a connectivity error authenticating. Shows a full-screen error if
 *                                  true.
 */
const AppBody = ({ storeToken, match, loaded, invalidToken, loadFailed, loadError }) => {
    const token = match?.params?.token;

    React.useEffect(() => {
        storeToken(token);
    }, [storeToken, token]);

    if (invalidToken || loadFailed) return <Redirect to="/error/invalidToken/" />;
    if (loadError) return <Redirect to="/error/unableToConnect/" />;

    
    return <>
        <Container>
            <Collapse in={!loaded} unmountOnExit>
                <Container maxWidth="sm">
                    <Skeleton variant="rectangular" width={552} height={292} />
                </Container>
            </Collapse>
            <Collapse in={loaded} unmountOnExit>
                <Switch>
                    <Route path="/:token/video/" exact>
                        <VideoTestPanel />
                    </Route>
                    <Route path="/:token/start/" exact>
                        <Container maxWidth="sm">
                            <TestRunnerPanel />
                        </Container>
                    </Route>
                    <Route path="/:token/">
                        <Redirect to={`/${token}/start/`}></Redirect>
                    </Route>
                </Switch>
            </Collapse>
        </Container>
    </>;
};

const mapStateToProps = (state) => {
    return {
        invalidToken: state.resultSubmitReducer.invalidToken,
        loaded: state.configReducer.loaded,
        loadError: state.configReducer.loadError,
        loadFailed: state.configReducer.loadFailed
    };
};

const mapDispatchToProps = {
    storeToken: (token) => {
        return ({type: actions.STORE_TOKEN, token: token});
    },
    launchRecordingTest: () => {
        return ({type: actions.LAUNCH_RECORDING_TEST});
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBody);
