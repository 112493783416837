/** @module testModules:core */

/**
 * States that are mostly used for running tests, but occasionally for a
 * related step (eg: uploading test results)
 * @enum {string}
 */
const TestStepStates = {
    /**
     * The step has completed successfully, and resulted in a Pass.
     **/
    Passed: 'Passed',

    /**
     * The step has completed successfully, and resulted in a Failure.
     */
    Failed: 'Failed',

    /**
     * The step is currently running. Tests should stay in this state until all
     * relevant cleanup has been finished.
     */
    Running: 'Running',

    /**
     * The step is currently waiting to run. Use this when one step depends on
     * completion of another (eg: when two tests share resources)
     */
    Waiting: 'Waiting',

    /**
     * The step has completed successfully, has passed, but the results
     * indicate a sub-optimal result. This should be used in cases where a
     * usable, but degraded service can be provided (eg: bandwidth sufficient
     * to run the session, but with degraded video quality)
     */
    Warning: 'Warning',

    /**
     * The step failed to run - that is: an error occurred whilst running the
     * step.
     */
    Error: 'Error',

    /**
     * The step is disabled. Generally occurs when the step is irrelevant to the
     * type of session the test is being run for.
     */
    Disabled: 'Disabled'
};
export { TestStepStates };

/**
 * Checks that the state passed to the function is "completed" - that is,
 * there is no more work to be done on it.
 */
export const stateIsCompleted = (state) => {
    return (
        state === TestStepStates.Passed ||
        state === TestStepStates.Failed ||
        state === TestStepStates.Warning ||
        state === TestStepStates.Error
    );
};

/**
 * Checks that the step has not reached a state that warrants an attention -
 * that is: it either has not completed, or has completed successfully.
 */
export const stateIsGood = (state) => {
    return (
        state === TestStepStates.Passed ||
        state === TestStepStates.Running ||
        state === TestStepStates.Waiting
    );
};

/**
 * Checks that the step has not reached a state that warrants an attention -
 * that is: it either has not completed, or has completed successfully.
 */
export const stateIsBad = (state) => {
    return !stateIsGood(state);
};

/**
 * Builds a mapStateToProps function for a given TEST_ID
 **/
export const buildMapStateToProps = (testId) => {
    return (state) => {
        const resultsObject = state.testsReducer.results[testId];
        const settings = state.configReducer.testsToRun.find(e => e.type === testId);
        return {
            status: resultsObject?.status ?? TestStepStates.Disabled,
            result: resultsObject?.result,
            progressPercentage: resultsObject?.progressPercentage,
            settings: settings ?? {},
            productType: state.configReducer.productType
        };
    };
};

/**
 * Returns a promise that resolves after the specified time.
 * @param {integer} ms Length of time to sleep in milliseconds.
 */
export async function sleep (ms) {
    return new Promise((res) => setTimeout(() => {res();}, ms));
}

/**
 * Utility call to convert browser exceptions to objects suitable for JSON representation.
 * @param {Exception} e 
 */
export function exceptionToObject (e) {
    return {
        code: e.code,
        name: e.name,
        message: e.message
    };
}

