import * as actions from '../actionsTypes';

const initialState = {
    token: null,
    invalidToken: false
};

const resultSubmitReducer = (state, action) => { 
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actions.STORE_TOKEN: {
            const token = action.token;
            if (!action.token || action.token.length < 20) {
                return {
                    ...state,
                    invalidToken: true
                };
            } else {
                return {
                    ...state,
                    token: token
                };
            }
        }
        default: {
            return state;
        }
    }
};

export default resultSubmitReducer;
