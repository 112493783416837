import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import { TestStepStates, buildMapStateToProps, sleep, exceptionToObject } from './core';
import { testStatusUpdate } from '../store/actions';
import { connect } from 'react-redux';
import Twilio from 'twilio-video';
import ResultRow from '../components/automatedTests/ResultRow';
import { useTranslation } from 'react-i18next';
import { TwilioVideoError, TwilioVideoFailed, TwilioVideoWarning } from '../components/automatedTests/resultsSummaries';
export const TEST_ID = 'TwilioConnectivity';

/**
 * Connectivity test for Twilio Video. Makes two connections to a room and pulls bandwidth stats for the resulting
 * connection.
 * @module testModules:twilioVideo
 */

const selectTokens = (store) => {
    return {
        send: store.configReducer.recordingSystem.sendApiKey,
        receive: store.configReducer.recordingSystem.receiveApiKey
    };
};

const connectToTwilio = async (token) => {
    const room = await Twilio.connect(token, {
        name:'test-room',
        audio: true,
        video: true,
        networkQuality: {
            local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 0 // RemoteParticipants' Network Quality verbosity [0 - 3]
        }
    });
    return room;
};
export function *runTest () {
    yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null));
    const tokens = yield select(selectTokens);
    let status = TestStepStates.Failed;
    const result = {
        exception: null,
        state: 'not done'
    };
    const videoElement = document.createElement('video');
    try {
        const room1 = yield call(connectToTwilio, tokens.send);
        const room2 = yield call(connectToTwilio, tokens.receive);

        // Loop sleeping here to ensure we get the stats we need. Twilio's stat events
        // don't fire routinely enough to count on.
        const MAX_ITERATIONS = 50;
        for (var i = 0; i < MAX_ITERATIONS; i++) {
            const baseObj = room1?._signaling?._networkQualityMonitor?.levels?.video;
            yield call(sleep, 500);
            result.stats_recvBandwidth = baseObj?.recvStats?.bandwidth?.actual;
            result.stats_recvLatency = baseObj?.recvStats?.latency?.rtt;
            result.stats_sendBandwidth = baseObj?.sendStats?.bandwidth?.actual;
            result.stats_sendLatency = baseObj?.sendStats?.latency?.rtt;
            result.stats_sendJitter = baseObj?.sendStats?.latency?.jitter;
            
            if (result.stats_recvBandwidth
                && result.stats_recvLatency
                && result.stats_sendBandwidth
                && result.stats_sendLatency
                && result.stats_sendJitter) {
                break;
            }
            const progressPercentage = 90 * i/MAX_ITERATIONS;
            yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null, progressPercentage));
        }
        yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null, 95));
        
        room1.disconnect();
        room2.disconnect();
        room1.disconnect();

        const recvBandwidthError = result.stats_recvBandwidth < 5000;
        const recvBandwidthWarning = result.stats_recvBandwidth < 15000;
        const sendBandwidthError = result.stats_sendBandwidth < 5000;
        const sendBandwidthWarning = result.stats_sendBandwidth < 15000;
        const sendLatencyError = result.stats_sendLatency > 0.4;
        const sendLatencyWarning = result.stats_sendLatency > 0.4;
        if (!(result.stats_recvBandwidth
            && result.stats_recvLatency
            && result.stats_sendBandwidth
            && result.stats_sendLatency
            && result.stats_sendJitter)) {
            status = TestStepStates.Error;
            result.exception = 'Failed to execute test - timed out.';
        } else if (recvBandwidthError || sendBandwidthError || sendLatencyError) {
            status = TestStepStates.Failed;
        } else if (recvBandwidthWarning || sendBandwidthWarning || sendLatencyWarning) {
            status = TestStepStates.Warning;
        } else {
            status = TestStepStates.Passed;
        }
        result.state = 'completed';
    } catch (e) {
        result.exception = exceptionToObject(e).name;
        console.error(result, e);
        status = TestStepStates.Error;
        yield call(sleep, 600); // For looks
    } finally {
        videoElement.remove();
    }
    yield put (testStatusUpdate(TEST_ID, status, result));
}

const TwilioVideoResultRow = ({status, progressPercentage}) => {
    const { t } = useTranslation();
    let error = '';

    switch (status) {
        case TestStepStates.Error:
            error = <TwilioVideoError />;
            break;
        case TestStepStates.Failed:
            error = <TwilioVideoFailed />;
            break;
        case TestStepStates.Warning:
            error = <TwilioVideoWarning />;
            break;
        default:
            break;
    }

    return <ResultRow status={status} title={t('automaticTests.results.twilioVideo.title')} subtitle={t('automaticTests.results.twilioVideo.subtitle')} errorMessage={error} progressPercentage={progressPercentage} />;
};

const mapStateToProps = buildMapStateToProps(TEST_ID);

export const TwilioVideoResultRowContainer = connect(mapStateToProps)(TwilioVideoResultRow);
