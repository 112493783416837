import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { SentimentVerySatisfied, ArrowForward } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import * as actions from '../../store/actionsTypes';
import { connect } from 'react-redux';
import Success from '../Success';

/** @module components:automatedTests:AutomatedTestSuccess */

const useStyles = makeStyles((theme) => {
    return {
        button: {
            background: 'green',
            color: theme.palette.getContrastText(theme.palette.success.dark),
            '&:hover': {
                background: theme.palette.success.main
            }
        }
    };
});

/**
 * View presented to users on successful completion of the automated tests stage.
 * Can optionally show a link to start the recording test.
 * @param {Object} props
 */
export const AutomatedTestSuccess = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <Success
        IconComponent={SentimentVerySatisfied}
        header={t('intervalPage.header')}
        body={t('intervalPage.body')}
        extra={<Button variant="contained" endIcon={<ArrowForward />} component={RouterLink} className={classes.button} to="../video/">
            {t('intervalPage.startRecordingTestButtonText')}
        </Button>}
    />;
};

const mapStateToProps = () => {
    return { };
};

const mapDispatchToProps = {
    launchRecordingTest: () => {
        return ({type: actions.LAUNCH_RECORDING_TEST});
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedTestSuccess);
