const theme = {
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#bf202b'
        },
        text: {
            secondary: '#555'
        },
    }
};

export default theme;