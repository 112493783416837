/**
 * A set of possible events that a login may represent.
 * @readonly
 * @enum {number}
 */
export const LoginType = {
    /** Event represents joining a session */
    SessionJoin: 0,
    /** Event representing the initial auth step for a sytem test */
    SystemTest: 1
};
