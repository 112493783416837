import React from 'react';
import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Error, WarningRounded } from '@mui/icons-material';

/**
 * A collection of components to show full page error messages.
 * @module components:errors
 */

const useStyles = makeStyles((theme) => ({
    bodyContainer: {
        padding: 32,
        textAlign: 'center'
    },
    errorIcon: {
        marginBottom: 16,
        height: '4rem',
        fontSize: '4rem'
    },
    warningIcon: {
        color: theme.palette.warning.main
    }
}));

/**
 * Wrapper around the Material UI Icon components, applies some basic styling (size, colour).
 * @param {Object} props NOTE: Props not handled explicitly are passed through to the inner Material UI Icon component.
 * @param {string} [props.variant=error] Either "warning" or "error" - picks which icon and colour to use.
 */
const ErrorComponentIcon = ({variant, ...props}) => {
    const classes = useStyles();
    const commonProps = {
        fontSize: 'large',
        ...props
    };
    switch (variant) {
        case 'warning':
            return <WarningRounded {...commonProps} className={[classes.warningIcon, props.className].join(' ')} />;
        case 'error':
        default:
            return <Error color="error" {...commonProps} />;
    }
};

/**
 * A component to render a full-page error message.
 * @param {Object} props React props
 * @param {string} props.title A short summary of the error (only a few words).
 * @param {string} props.message A "body" of the error message - should explain the error in some detail.
 * @param {string} props.variant=error As per ErrorComponentIcon - determines which icon/colour to use.
 */
export function BasicFullPageError ({title, message, variant = 'error'}) {
    const classes = useStyles();
    return <Container maxWidth="sm"  className={classes.bodyContainer}>
        <ErrorComponentIcon variant={variant} className={classes.errorIcon} />
        <Typography variant="h6" paragraph>{title}</Typography>
        <Typography variant="body1">{message}</Typography>
    </Container>;
}

/**
 * A component to show a suitable error when the URI contained an invalid authentication token.
 */
export function InvalidToken () {
    const { t } = useTranslation();
    return <BasicFullPageError title={t('errors.invalidLink.title')} message={t('errors.invalidLink.message')} />;
}

/**
 * A component to show a suitable error when the app was unable to connect to the backend server for authentication.
 */
export function UnableToConnect () {
    const { t } = useTranslation();
    return <BasicFullPageError title={t('errors.unableToConnect.title')} message={t('errors.unableToConnect.message')} />;
}


