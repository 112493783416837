import React from 'react';
import { call, put } from 'redux-saga/effects';
import { TestStepStates, buildMapStateToProps, sleep } from './core';
import { testStatusUpdate } from '../store/actions';
import { connect } from 'react-redux';
import ResultRow from '../components/automatedTests/ResultRow';
import { MissingFeatures } from '../components/automatedTests/resultsSummaries';
import { useTranslation } from 'react-i18next';

/**
 * Tests that specific HTML/JS features are available in the user's browser.
 * Currently supports:
 * - audio
 * - canvas
 * - video
 * - websockets
 * - webrtc
 * @module testModules:browserFeatureDetection
 */

const PASS = () => ({passed: true});
const FAIL = (detail) => ({...detail, passed: false});  

export const TEST_ID = 'BrowserFeatures';

export function *runTest () {
    yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null));
    
    const tests = {
        audio: () => {
            const failMessage = {message: 'Missing/incomplete support for audio elements'};
            const element = document.createElement('audio');
            if (element.autoplay === undefined) return FAIL(failMessage);
            if (element.muted === undefined) return FAIL(failMessage);
            if (element.volume === undefined) return FAIL(failMessage);
            if (element.duration === undefined) return FAIL(failMessage);
            return PASS();
        },
        canvas: () => {
            const failMessage = {message: 'Missing/incomplete support for canvas elements'};
            const element = document.createElement('canvas');
            if (element.getContext === undefined) return FAIL(failMessage);
            return PASS();
        },
        video: () => {
            const failMessage = {message: 'Missing/incomplete support for video elements'};
            const element = document.createElement('audio');
            if (element.autoplay === undefined) return FAIL(failMessage);
            if (element.muted === undefined) return FAIL(failMessage);
            if (element.volume === undefined) return FAIL(failMessage);
            if (element.duration === undefined) return FAIL(failMessage);
            return PASS();
        },
        websockets: () => {
            const failMessage = {message: 'Missing/incomplete support for websockets'};
            if (WebSocket === undefined) return FAIL(failMessage);
            else return PASS();
        },
        webrtc: () => {
            const failMessage = {message: 'Missing/incomplete support for WebRTC'};
            if (navigator.mediaDevices === undefined) return FAIL(failMessage);
            if (navigator.mediaDevices.getUserMedia === undefined) return FAIL(failMessage);
            if (navigator.mediaDevices.enumerateDevices === undefined) return FAIL(failMessage);
            if (MediaStream === undefined) return FAIL(failMessage);
            return PASS();
        }
    };

    yield call(sleep, 600); // For looks

    let passed = true;
    const testResults = {};
    Object.entries(tests).forEach(([key, test]) => {
        const results = test();
        testResults[key] = results.passed;
        passed = passed && results.passed;
        if (!results.passed) console.error('Failed test ', key);
    });

    yield put(testStatusUpdate(TEST_ID, passed ? TestStepStates.Passed : TestStepStates.Failed, testResults));
}

const BrowserFeatureDetectionResultRow = ({status, progressPercentage}) => {
    const { t } = useTranslation();
    const browserFeaturesError = status === TestStepStates.Failed ? <MissingFeatures key="missingFeatures" /> : null;
    return <ResultRow status={status} title={t('automaticTests.results.browserFeatureDetection.title')} subtitle={t('automaticTests.results.browserFeatureDetection.subtitle')} errorMessage={browserFeaturesError} progressPercentage={progressPercentage} />;
};

const mapStateToProps = buildMapStateToProps(TEST_ID);

export const BrowserFeatureDetectionResultRowContainer = connect(mapStateToProps)(BrowserFeatureDetectionResultRow);
