import * as actions from '../actionsTypes';
/** @module store:reducers:videoTestReducer */

/**
 * List of states that a recording process can be in.
 * @enum {string}
 */
const RecordingStates = {
    /** The process is not yet ready to start. */
    INITIALIZING: 'INITIALIZING',
    /** The process has not yet started, and is ready to be started. */
    READY: 'READY',
    /** The process has been started, but is not yet recording. */
    STARTING: 'STARTING',
    /** The process has started and the recording is running. */
    STARTED: 'STARTED',
    /** The recording has completed, and the recording is processing and/or uploading. */
    PROCESSING: 'PROCESSING',
    /** The process has failed to complete, due to an error. */
    ERROR: 'ERROR',
    /** The process has completed successfully, and the recording has been uploaded. */
    COMPLETED: 'COMPLETED',
    /** The user has confirmed the recording is correct and asked for it to be saved. */
    SAVING: 'SAVING',
    /** The recording has finished saving. This marks the end of this process. */
    SAVE_COMPLETE: 'SAVE_COMPLETE',
    /** An error occurred, specifically whilst saving the recording. */
    SAVE_ERROR: 'SAVE_ERROR'
};

const initialState = {
    videoTrack: null,
    audioTrack: null,
    initialized: false,
    recordingUri: null,
    progress: null,
    recordProgress: 0,
    status: RecordingStates.INITIALIZING
};

/** Reducer to handle state for the Video Recording test. */
const videoTestReducer = (state, action) => { 
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actions.VIDEO_RECORDING_CREATE_TRACKS: {
            return {...initialState};
        }
        case actions.VIDEO_RECORDING_TRACKS_CREATED: {
            const videoTrack = action.payload.videoTrack;
            const audioTrack = action.payload.audioTrack;
            const videoStream = action.payload.videoStream;
            const audioStream = action.payload.audioStream;
            return {...state,
                videoTrack,
                audioTrack,
                videoStream,
                audioStream,
                initialized: true,
                status: RecordingStates.READY
            };
        }
        case actions.VIDEO_RECORDING_START_RECORDING: {
            return { ...state,
                status: RecordingStates.STARTING
            };
        }
        case actions.VIDEO_RECORDING_RECORDING_STARTED: {
            return { ...state,
                status: RecordingStates.STARTED,
                recordProgress: 0
            };
        }
        case actions.VIDEO_RECORDING_RECORD_PROGRESS: {
            return { ...state,
                recordProgress: action.progress
            };
        }
        case actions.VIDEO_RECORDING_RECORDING_STOPPED: {
            return { ...state,
                status: RecordingStates.PROCESSING,
                recordProgress: 0
            };
        }
        case actions.VIDEO_RECORDING_RECORDING_ERROR: {
            return { ...state,
                status: RecordingStates.ERROR
            };
        }
        case actions.VIDEO_RECORDING_RECORDING_COMPLETED: {
            return { ...state,
                status: RecordingStates.COMPLETED,
                recordingUri: action.uri
            };
        }
        case actions.VIDEO_RECORDING_TRACKS_RESET_DONE: {
            return { ...state,
                status: RecordingStates.READY,
                recordingUri: null
            };
        }
        case actions.VIDEO_RECORDING_TRACKS_RESET: {
            return { ...state,
                status: RecordingStates.INITIALIZING
            };
        }
        case actions.VIDEO_RECORDING_SAVE_RECORDING: {
            return { ...state,
                status: RecordingStates.SAVING
            };
        }
        case actions.VIDEO_RECORDING_SAVE_COMPLETE: {
            return { ...state,
                status: RecordingStates.SAVE_COMPLETE
            };
        }
        case actions.VIDEO_RECORDING_SAVE_ERROR: {
            return { ...state,
                status: RecordingStates.SAVE_ERROR
            };
        }
        case actions.VIDEO_RECORDING_SAVE_PROGRESS: {
            return { ...state,
                progress: action.progress
            };
        }
        default: {
            return state;
        }
    }
};

export { RecordingStates };
export default videoTestReducer;
