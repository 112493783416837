import { combineReducers } from 'redux';
import testsReducer from './testsReducer';
import resultSubmitReducer from './resultSubmitReducer';
import configReducer from './configReducer';
import videoTestReducer from './videoTestReducer';

/**
 * Module containing a set of reducers (as submodules) to deal with incoming actions on the Redux store.
 * @module store:reducers
 */

export const rootReducer = combineReducers({
    testsReducer,
    resultSubmitReducer,
    configReducer,
    videoTestReducer
});
