import React from 'react';
import { RecordingStates } from '../../store/reducers/videoTestReducer';
import { Collapse, Paper, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VideoRecordingSuccess from './VideoRecordingSuccess';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 30
    },
    progress: {
        marginBottom: 30,
        marginTop: 10
    }
});

function UploadProgressPanel ({status, saveProgress}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const saveIsComplete = status === RecordingStates.SAVE_COMPLETE;
    return <>
        <Collapse in={!saveIsComplete}>
            <Paper className={classes.paper}>
                <CircularProgress variant="determinate" value={saveProgress*100} size={150} className={classes.progress} />
                <Typography variant="h6">{t('videoRecording.uploadProgress.title')}</Typography>
            </Paper>
        </Collapse>
        <Collapse in={saveIsComplete}>
            <VideoRecordingSuccess />
        </Collapse>
    </>;
}

export default UploadProgressPanel;
