import React from 'react';
import { call, put } from 'redux-saga/effects';
import { TestStepStates, buildMapStateToProps, sleep } from './core';
import { testStatusUpdate } from '../store/actions';
import { connect } from 'react-redux';
import ResultRow from '../components/automatedTests/ResultRow';
import { ScreenSizeFail } from '../components/automatedTests/resultsSummaries';
import { useTranslation } from 'react-i18next';

/**
 * Checks that the user's screen is a high enough resolution.
 * @module testModules:screenSize
 */
export const TEST_ID = 'ScreenSize';

/**
 * Actual test implementation.
 * @param {Object} kwargs arguments, accepted as an object:
 * @param {int} kwargs.defaultMinWidth minimum width, below this the test will FAIL
 * @param {int} kwargs.defaultMinHeight minimum height, below this the test will FAIL
 * @param {int} kwargs.mobileMinWidth minimum width for mobiles, below this the test will FAIL
 * @param {int} kwargs.mobileMinHeight minimum height for mobiles, below this the test will FAIL
 */
export function *runTest ({
    defaultMinWidth,
    defaultMinHeight,
    mobileMinWidth,
    mobileMinHeight
}) {
    try {
        yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null));

        const isMobileDevice = () => navigator.userAgent.match(/mobile/i) && !navigator.userAgent.match(/iPad/i);

        let activeMinWidth = defaultMinWidth;
        let activeMinHeight = defaultMinHeight;

        if (isMobileDevice()) {
            // Allow smaller screens for mobiles
            activeMinWidth = mobileMinWidth;
            activeMinHeight = mobileMinHeight;
        }

        const screen = window.screen;
        const failed = (
            screen.width < activeMinWidth ||
            screen.height < activeMinHeight
        );
        const screenResolution = {
            width: screen.width,
            height: screen.height
        };
        yield call(sleep, 300); // For looks

        let status = TestStepStates.Passed;
        if (failed) status = TestStepStates.Failed;

        yield put(testStatusUpdate(TEST_ID, status, screenResolution));
    } catch (e) {
        console.error('Error whilst running Screen Size test: ', e);
        yield put(testStatusUpdate(TEST_ID, TestStepStates.Error));
    }
}

const ScreenSizeResultRow = ({status, progressPercentage, settings}) => {
    const { t } = useTranslation();
    let error = null;
    if (status === TestStepStates.Failed) {
        error = <ScreenSizeFail key="badScreen" minWidth={settings.minWidth} minHeight={settings.minHeight} />;
    }

    return <ResultRow status={status} title={t('automaticTests.results.screenSize.title')} subtitle={t('automaticTests.results.screenSize.subtitle')} errorMessage={error} progressPercentage={progressPercentage} />;
};

const mapStateToProps = buildMapStateToProps(TEST_ID);

export const ScreenSizeResultRowContainer = connect(mapStateToProps)(ScreenSizeResultRow);
