/** @module store:actionTypes */

// Blank JSDoc comments are added to ensure the action types appear in docs.

// --------- Automated tests
export const RUN_ALL_TESTS = 'RUN_ALL_TESTS'; /** */
export const TEST_RUN_COMPLETE = 'TEST_RUN_COMPLETE'; /** */
export const FEATURE_DETECTION_COMPLETE = 'FEATURE_DETECTION_COMPLETE'; /** */
export const BROWSER_VERSION_COMPLETE = 'BROWSER_VERSION_COMPLETE'; /** */
export const HARDWARE_CHECKS_COMPLETE = 'HARDWARE_CHECKS_COMPLETE'; /** */
export const VIDEO_CAPTURE_TEST_COMPLETE = 'VIDEO_CAPTURE_TEST_COMPLETE'; /** */
export const AUDIO_CAPTURE_TEST_COMPLETE = 'AUDIO_CAPTURE_TEST_COMPLETE'; /** */
export const SUBMIT_RESULTS = 'SUBMIT_RESULTS'; /** */
export const RESULTS_SUBMITTED_SUCCESS = 'RESULTS_SUBMITTED_SUCCESS'; /** */
export const RESULTS_SUBMITTED_FAILURE = 'RESULTS_SUBMITTED_FAILURE'; /** */
export const STORE_TOKEN = 'STORE_TOKEN'; /** */
export const TEST_STATUS_UPDATE = 'TEST_STATUS_UPDATE'; /** */
export const RESET_TESTS = 'RESET_TESTS'; /** */

// --------- Recording tests
export const LAUNCH_RECORDING_TEST = 'LAUNCH_RECORDING_TEST'; /** */

// --------- Authentication
export const AUTHENTICATION_COMPLETE_FAILED = 'AUTHENTICATION_COMPLETE_FAILED'; /** */
export const AUTHENTICATION_COMPLETE_ERROR = 'AUTHENTICATION_COMPLETE_ERROR'; /** */
export const AUTHENTICATION_COMPLETE_SUCCESS = 'AUTHENTICATION_COMPLETE_SUCCESS'; /** */
export const RUN_AUTHENTICATION = 'RUN_AUTHENTICATION'; /** */

// --------- Recordings
export const VIDEO_RECORDING_START_RECORDING = 'VIDEO_RECORDING_START_RECORDING'; /** */
export const VIDEO_RECORDING_RECORDING_STARTED = 'VIDEO_RECORDING_RECORDING_STARTED'; /** */
export const VIDEO_RECORDING_RECORDING_STOPPED = 'VIDEO_RECORDING_RECORDING_STOPPED'; /** */
export const VIDEO_RECORDING_RECORDING_ERROR = 'VIDEO_RECORDING_RECORDING_ERROR'; /** */
export const VIDEO_RECORDING_RECORDING_COMPLETED = 'VIDEO_RECORDING_RECORDING_COMPLETED'; /** */
export const VIDEO_RECORDING_RECORD_PROGRESS = 'VIDEO_RECORDING_RECORD_PROGRESS'; /** */
export const VIDEO_RECORDING_CREATE_TRACKS = 'VIDEO_RECORDING_CREATE_TRACKS'; /** */
export const VIDEO_RECORDING_TRACKS_CREATED = 'VIDEO_RECORDING_TRACKS_CREATED'; /** */
export const VIDEO_RECORDING_TRACKS_RESET = 'VIDEO_RECORDING_TRACKS_RESET'; /** */
export const VIDEO_RECORDING_TRACKS_RESET_DONE = 'VIDEO_RECORDING_TRACKS_RESET_DONE'; /** */
export const VIDEO_RECORDING_SAVE_RECORDING = 'VIDEO_RECORDING_SAVE_RECORDING'; /** */
export const VIDEO_RECORDING_SAVE_COMPLETE = 'VIDEO_RECORDING_SAVE_COMPLETE'; /** */
export const VIDEO_RECORDING_SAVE_ERROR = 'VIDEO_RECORDING_SAVE_ERROR'; /** */
export const VIDEO_RECORDING_SAVE_PROGRESS = 'VIDEO_RECORDING_SAVE_PROGRESS'; /** */

