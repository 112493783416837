import React, { useEffect } from 'react';
import { useRef } from 'react';

/** @module components:videoRecordingTest:TwilioVideoTrack */

/**
 * Renders a suitable media element for the specified Twilio Video API track. Handles attach/detach events, and renders
 * the correct type of element for the provided track. Recommended use is to construct a LocalAudioTrack or
 * LocalVideoTrack and attach this locally before the room connection is opened.
 * 
 * @param {Object} props NOTE: props not specified below are passed to the child media element.
 * @param {TwilioVideo:Track}  props.track A Twilio Video API track - actively supports audio or video tracks, and
 *                                         renders nothing for other types.
 */
function TwilioVideoTrack ({track, ...props}) {
    const mediaElement = useRef();
    const kind = track?.kind;
    useEffect(() => {
        const element = mediaElement.current;
        const isValidType = kind === 'video' || kind === 'audio';
        if (isValidType) {
            track.attach(element);
        }

        return function cleanup() {
            if (isValidType) {
                track.detach(element);
            }
        };
    }, [track, kind]);

    switch (kind) {
        case 'video': {
            return <video muted={true} ref={mediaElement} {...props} />;
        }
        case 'audio': {
            return <audio ref={mediaElement} {...props} />;
        }
        default: {
            return null;
        }
    }
}

export default TwilioVideoTrack;
