import React from 'react';
import { Typography, Container, Button, Collapse, Paper, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actionsTypes';
import AutomatedTestSuccess from './AutomatedTestSuccess';
import { useTranslation } from 'react-i18next';
import ResultsTable from './ResultsTable';
import { TestStepStates } from '../../testModules/core';
import { WarningRounded } from '@mui/icons-material';

/** @module components:automatedTests:TestRunnerPanel */

const useStyles = makeStyles(() => ({
    bodyContainer: {
        paddingTop: 32,
        textAlign: 'center'
    },
    tableContainer: {
        marginTop: 16
    },
    statusIcon: {
        width: 32,
        height: 32
    },
    resultCell: {
        padding: 0
    },
    resultInner: {
        padding: 16
    },
    resultsSummaryBox: {
        paddingTop: 32,
        paddingBottom: 32
    },
    pagePaper: {
        paddingBottom: 16,
        paddingTop: 16,
        marginTop: 16,
        marginBottom: 16
    },
    failureIconBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    failureIcon: {
        width: '192px',
        height: '192px',
        filter: 'drop-shadow( 0 1px 1px rgba(0, 0, 0, .7))'
    }
}));

/**
 * Some basic instructions for the user on how the testing process will work. Displayed before the tests are run.
 */
const Introduction = () => {
    const { t } = useTranslation();
    return <>
        <Typography variant="body1" paragraph>
            {t('introduction.line1')}
        </Typography>
        <Typography variant="body1" paragraph>
            {t('introduction.line2')}
        </Typography>
        <Typography variant="body1" paragraph>
            {t('introduction.line3')}
        </Typography>
    </>;
};

/**
 * Indicator displayed on the test run when a test has failed in some way.
 */
const FailHeader = () => {
    const classes = useStyles();
    return <Box className={classes.failureIconBox}>
        <WarningRounded className={classes.failureIcon} color="error" />
    </Box>;
};

/**
 * Root component for displaying pending, executing and completed automated test runs. Automatically dismisses the
 * results view if all were successful - otherwise sticks around to show the errors that were returned.
 * 
 * @param {Object} props
 * @param {function} props.runAllTests Callback to start the test run.
 * @param {Object} props.tests A list of test definitions, only e.testId is retained.
 * @param {Object} props.results An objecting containing test states. Indexed on testId.
 * @param {boolean} props.testsRunning A flag to indicate that tests are still running.
 */
const TestRunnerPanel = (props) => {
    const classes = useStyles();

    const haveRunTests = useSelector(store => store.testsReducer.haveRunTests);

    const clickRun = () => {
        props.runAllTests();
    };
    
    const testIds = props.tests
        ?.map(e => typeof(e) === 'object' ? e.type : e);
        
    const testStatuses = testIds
        ?.map(testId => props.results[testId].status);
        
    const overallSuccess = testStatuses
        ?.filter(e => e !== TestStepStates.Disabled)
        .every(e => e === TestStepStates.Passed);
    
    const { t } = useTranslation();

    const showFailureHeader = haveRunTests && (!props.testsRunning && !overallSuccess);
    const showResultsTable = haveRunTests && (props.testsRunning || !overallSuccess);
    const showSuccess = haveRunTests && (!props.testsRunning && overallSuccess);

    return <>
    
        <Collapse in={!haveRunTests}>
            <Paper className={classes.pagePaper}>
                <Container maxWidth="sm" className={classes.bodyContainer}>
                    <Introduction />
                    <Button variant="contained" color="secondary" onClick={clickRun} disabled={props.testsRunning}>{t('automaticTests.startButton')}</Button>
                </Container>
            </Paper>
        </Collapse>
        <Collapse in={showFailureHeader} unmountOnExit>
            <FailHeader />
        </Collapse>
        <Collapse in={showResultsTable} unmountOnExit>
            <ResultsTable testsRunning={props.testsRunning} tests={props.tests} results={props.results} haveRunTests={haveRunTests} />
        </Collapse>
        <Collapse in={showSuccess} unmountOnExit>
            <AutomatedTestSuccess />
        </Collapse>
    </>;
};

const mapStateToProps = (state) => {
    return {
        testsRunning: state.testsReducer.testsRunning,
        results: state.testsReducer.results,
        tests: state.testsReducer.tests,
        invalidToken: state.resultSubmitReducer.invalidToken
    };
};

const mapDispatchToProps = {
    runAllTests: () => {
        return ({type: actions.RUN_ALL_TESTS});
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TestRunnerPanel);
