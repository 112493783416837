import {applyMiddleware, compose, createStore} from 'redux';
import {rootReducer} from './reducers';
import rootSaga from '../sagas/rootSaga';
import createSagaMiddleware from 'redux-saga';

const configureStore = (preloadedState) => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer];
    if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    const composedEnhancers = compose(...enhancers);
    const store = createStore(rootReducer, preloadedState, composedEnhancers);
    sagaMiddleware.run(rootSaga);
    return store;
};

export const store = configureStore();


