import React from 'react';
import { Collapse, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Done, Error } from '@mui/icons-material';
import {
    Box,
    CardContent
} from '@mui/material';
import { TestStepStates, stateIsBad } from '../../testModules/core';

/**
 * @module components:automatedTests:ResultRow
 */

const useStyles = makeStyles(() => ({
    statusIcon: {
        width: 32,
        height: 32
    },
    baseBox: {
        borderBottom: '1px #ddd solid',
        display: 'flex'
    },
    resultRowTextBox: {
        marginLeft: 16
    },
    iconBox: {
        width: 32
    },
    errorMessageBlock: {
        marginTop: 8,
        marginBottom: -8
    }
}));

/**
 * Component to summarise the state and results of one test type within a run.
 * Not shown by default unless there's an error - shold be considered a "drilled down" view.
 */
const ResultRow = ({status, title, subtitle, errorMessage, progressPercentage}) => {
    const classes = useStyles();

    if (status === TestStepStates.Disabled) {
        return null;
    }
    
    const getIcon = (status) => {
        switch(status) {
            case TestStepStates.Passed:
                return <Done className={classes.statusIcon} />;
            case TestStepStates.Error:
            case TestStepStates.Failed:
            case TestStepStates.Warning:
                return <Error className={classes.statusIcon} color="error" />;
            case TestStepStates.Running:
                if (typeof(progressPercentage) === 'number') {
                    return <CircularProgress value={progressPercentage} variant="determinate" size={32} />;
                } else {
                    return <CircularProgress size={32} />;
                }
            case TestStepStates.Waiting:
            default:
                return <CircularProgress size={32} />;
        }
    };

    return <CardContent className={classes.baseBox}>
        <Box className={classes.iconBox}>
            {getIcon(status)}
        </Box>
        <Box className={classes.resultRowTextBox}>
            <Typography variant="body2">{title}</Typography>
            <Typography variant="caption" color="textSecondary">{subtitle}</Typography>
            <Collapse in={stateIsBad(status) && errorMessage !== null}>
                <div className={classes.errorMessageBlock}>
                    {errorMessage}
                </div>
            </Collapse>
        </Box>
    </CardContent>;
};

export default ResultRow;
