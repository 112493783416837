import React, { useCallback } from 'react';
import { CardMedia, Box, IconButton, Zoom, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PlayArrow, Pause } from '@mui/icons-material';
import TwilioVideoTrack from './TwilioVideoTrack';
import { RecordingStates } from '../../store/reducers/videoTestReducer';
import PropTypes from 'prop-types';

/** @module components:videoRecordingTest:PanelContent */

const useStyles = makeStyles((theme) => ({
    videoControls: {
        minHeight: 'initial',
        maxHeight: 'initial',
        marginTop: -35,
        placeSelf: 'start',
        paddingBottom: 5,
        paddingLeft: 5,
        '& button': {
            filter: 'drop-shadow(white 0 0 2px) drop-shadow(white 0 0 2px) drop-shadow(white 0 0 2px)',
        }
    },
    pauseButton: {
        marginLeft: -30
    },
    loadingBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: theme.palette.background.default
    },
    videoInitSpinner: {
        color: theme.palette.getContrastText(theme.palette.background.default)
    },
    videoAlignmentBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: '#222'
    }
}));


/**
 * A component to render the currently viewed media in the `VideoTestPanel`. Automatically switches between live
 * preview and playback of the recording.
 * @param {Object} props
 * @param {RecordingStates} props.status The current state of the recording process.
 * @param {TwilioVideo:LocalVideoTrack} props.videoTrack The current local video, for a "mirror" like preview when
 *                                                       making the recording.
 * @param {string} props.recordingUri URL where the recording is currently stored, if applicable. Set when the
 *                                    recording is ready to preview.
 * @param {function} props.setPlaying Callback to set whether the video should currently be playing or not.
 * @param {boolean} props.playing Flag to indicate whether the video should currently be playing.
 */
function PanelContent ({status, videoTrack, recordingUri, setPlaying, playing}) {
    const classes = useStyles();

    const mediaRef = useCallback(ref => {
        if (ref !== null) {
            ref.onended = () => setPlaying(false);
            ref.onpause = () => setPlaying(false);
            ref.onplay = () => setPlaying(true);
            if (ref) {
                if (playing) {
                    if (ref.paused) {
                        ref.play();
                    }
                } else {
                    if (!ref.paused) {
                        ref.pause();
                    }
                }
            }
        }
    }, [setPlaying, playing]);

    switch (status) {
        case RecordingStates.INITIALIZING: {
            return <Box className={classes.loadingBox}>
                <CircularProgress size={80} className={classes.videoInitSpinner} />
            </Box>;
        }
        case RecordingStates.READY:
        case RecordingStates.STARTING:
        case RecordingStates.STARTED:
        case RecordingStates.PROCESSING: {
            return <CardMedia>
                <TwilioVideoTrack track={videoTrack} />
            </CardMedia>;
        }
        case RecordingStates.COMPLETED: {
            return <div className={classes.videoAlignmentBox}>
                <div>
                    <CardMedia component="video" src={recordingUri} ref={mediaRef} />
                    <Box className={classes.videoControls}>
                        <Zoom in={!playing}>
                            <IconButton color="primary" size="small" disabled={playing} onClick={() => {setPlaying(true);}}>
                                <PlayArrow />
                            </IconButton>
                        </Zoom>
                        <Zoom in={playing} className={classes.pauseButton}>
                            <IconButton color="primary" size="small" disabled={!playing} onClick={() => {setPlaying(false);}}>
                                <Pause />
                            </IconButton>
                        </Zoom>
                    </Box>
                </div>
            </div>;
        }
        case RecordingStates.ERROR:
        default: {
            return null;
        }
    }
}

PanelContent.propTypes = {
    status: PropTypes.string,
    videoTrack: PropTypes.object,
    recordingUri: PropTypes.string,
    setPlaying: PropTypes.func,
    playing: PropTypes.bool  
};

export default PanelContent;
