import React from 'react';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { HelpOutline } from '@mui/icons-material';

const HelpMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const theme = useTheme();
    const { t } = useTranslation();
  
    return <>
        <IconButton
            onClick={handleClick}
            aria-label={t('header.aboutMenu.openMenuButtonTooltip')}
        >
            <HelpOutline htmlColor={theme.palette.primary.contrastText} />
        </IconButton>
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
        >
            <MenuItem
                onClick={() => {
                    window.open('/third-party-notices.txt', '_blank');
                    handleClose();
                }}
            >
                {t('header.aboutMenu.thirdPartyNotices')}
            </MenuItem>
        </Menu>
    </>;
};

const VLAppBar = () => {
    const { t } = useTranslation();
    
    return (<AppBar position="fixed">
        <Toolbar>
            <Box sx={{flexGrow: 1}}>
                <Typography variant="h6" color="inherit">{t('titles.systemChecks')}</Typography>
            </Box>
            <Box sx={{flexGrow: 0}}>
                <HelpMenu />
            </Box>
        </Toolbar>
    </AppBar>);
};
export default VLAppBar;