import { call, put, select } from 'redux-saga/effects';
import * as actions from '../store/actionsTypes';
import { TestStepStates } from '../testModules/core';
import { API_BASE_URI } from '../Config';

/** @module sagas:resultSubmitSagas */

/** URI for submitting test results. Built from the REACT_APP_API_BASE_URL environment variable. */
const SUBMIT_URI = `${API_BASE_URI}api/systemtest/results`;
/**
 * Guid represented as a string - eg: 00000000-0000-0000-0000-000000000000
 * @typedef {string} Guid
 */
/**
 * SystemTestRun as used in the results API.
 * Should match the C# class specified in VLQR.FocusGroups.Web.Models.Api.SystemTest.TestRunModel
 * @class
 * @property {TestResult[]} Results - Array of TestResult objects, each of which holds information on a specific type of
 *                                 test
 * @property {TestStepStates} OverallResult 
 * @see {@link TestResult}
 */
class SystemTestRun {
    /**
     * @param {TestResult[]} Results 
     * @param {TestStepStates} OverallResult 
     */
    constructor (
        Results,
        OverallResult
    ) {
        this.Results = Results;
        this.OverallResult = OverallResult;
    }
}

/**
 * Representation of the result of a single test within the test run. Has its own pass/fail criteria and attributes
 * about any useful metrics that were collected. Eg: results of a screen size check.
 * Should match the C# class specified in VLQR.FocusGroups.Web.Models.Api.SystemTest.TestResultModel
 * @param {string} TestType Reference to which type of test is being run.
 * @param {TestStepStates} Result Overall result for this specific test. Calculated client-side.
 * @param {Object} Values A set of arbitrary values returned by this test. Useful for bandwidth figures, OS/Browser info, etc.
 */
class TestResult {
    constructor (
        TestType,
        Result,
        Values
    ) {
        this.TestType = TestType;
        this.Result = Result;
        this.Values = Values;
    }
}



const resultsSelector = (state) => {
    const rawResults = state.testsReducer.results;
    const testIds = state.testsReducer.tests.map(e => typeof(e) === 'string' ? e : e.type);

    // Get an overall pass/fail state
    const passedAllTests = testIds.map((e, prev) => {
        return prev && rawResults[e].status === TestStepStates.Passed;
    }, true);

    // Build the individual result objects for the response.
    const individualResults = Object.entries(rawResults).map(e => {
        const testId = e[0];
        const testResult = e[1];
        return new TestResult(testId, testResult.status, testResult.result);
    });

    const resultBody = new SystemTestRun(individualResults, passedAllTests ? TestStepStates.Passed : TestStepStates.Failed);
    return resultBody;
};

const tokenSelector = (state) => {
    return state.resultSubmitReducer.token;
};

export function *submitResult () {
    const results = yield select(resultsSelector);
    const token = yield select(tokenSelector);
    const result = yield call(async () => {
        try {
            await fetch(`${SUBMIT_URI}/${token}`, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(results)
            });
            return true;
        } catch(e) {
            return false;
        }
    });
    if (result) {
        yield put({type: actions.RESULTS_SUBMITTED_SUCCESS});
    } else {
        yield put({type: actions.RESULTS_SUBMITTED_FAILURE});
    }
}