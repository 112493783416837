import React, { useEffect } from 'react';
import 'typeface-roboto';
import {
    ThemeProvider,
    StyledEngineProvider,
    Grid,
    CssBaseline,
    adaptV4Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import {Route, Switch, Redirect, useRouteMatch} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InvalidToken, UnableToConnect } from './components/errors';
import AppBody from './components/AppBody';
import { connect } from 'react-redux';
import * as actions from './store/actionsTypes';
import { create } from 'jss';
import rtl from 'jss-rtl';
import rawTheme from './theme';
import { createTheme } from '@mui/material/styles';
import VLAppBar from './components/VLAppBar';
import PropTypes from 'prop-types';

/** @module App */

const theme = createTheme(adaptV4Theme(rawTheme));

const useStyles = makeStyles(() => ({
    bodyContainer: {
        paddingTop: 32,
        textAlign: 'center'
    },
    tableContainer: {
        marginTop: 16
    },
    statusIcon: {
        width: 32,
        height: 32
    },
    bodyGrid: {
        position: 'absolute',
        top: 0,
        minHeight: 'calc(100vh)'
    }
}));

/**
 * Root object for the application. Handles the App Bar, and some display of error messages. Otherwise displays the
 * AppBody component.
 * @param {Object} props
 * @param {function} props.runAuthentication Redux binding - called once when the component is mounted, if a valid
 *                                           token is found.
 */
const App = (props) => {
    const classes = useStyles(theme);
    const { i18n } = useTranslation();
    const match = useRouteMatch('/error/');
    const runAuthentication = props.runAuthentication;
    useEffect(() => {
        if (!match) runAuthentication();
    }, [match, runAuthentication]);

    theme.direction = i18n.dir();
    document.getElementsByTagName('body')[0].setAttribute('dir', i18n.dir());
    console.log(i18n.languages);

    const jssPlugins = [...jssPreset().plugins];
    if (i18n.dir() === 'rtl') {
        jssPlugins.push(rtl());
    }
    const jss = create({ plugins: jssPlugins });

    return (
        <StylesProvider jss={jss}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className={classes.root}>
                        <VLAppBar />
                        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.bodyGrid}>
                            <Switch>
                                <Route path="/error/invalidToken/" exact component={InvalidToken} />
                                <Route path="/error/unableToConnect/" exact component={UnableToConnect} />
                                <Route path="/:token/" component={AppBody} />
                                <Route path="/">
                                    <Redirect to="/error/invalidToken/" />
                                </Route>
                            </Switch>
                        </Grid>
                    </div>
                    <div id="remote-media-div"></div>
                </ThemeProvider>
            </StyledEngineProvider>
        </StylesProvider>
    );
};

App.propTypes = {
    runAuthentication: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        runAuthentication: () => dispatch({type: actions.RUN_AUTHENTICATION})
    };
};

export default connect(null, mapDispatchToProps)(App);

