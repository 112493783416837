import * as actions from '../actionsTypes';
import { TestStepStates } from '../../testModules/core';

const initialState = {
    testsRunning: false,
    haveRunTests: false,
    results: {}
};
const initialResultState = {
    status: TestStepStates.Waiting,
    result: null
};


const testsReducer = (state, action) => { 
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actions.RUN_ALL_TESTS: {
            return {
                // When we start a test run, blank state so we don't have any old results around
                ...initialState,
                testsRunning: true,
                haveRunTests: true
            };
        }
        case actions.RESET_TESTS: {
            const results = {};

            action.tests
                .map(e => typeof(e) === 'string' ? e : e.type)
                .forEach(testId => results[testId] = {...initialResultState});

            return {
                ...state,
                tests: action.tests,
                results
            };
        }
        case actions.TEST_STATUS_UPDATE: {
            const results = {
                ...state.results,
            };
            results[action.id] = {
                status: action.status,
                result: action.result,
                progressPercentage: action.progressPercentage
            };
            return {
                ...state,
                results: results
            };
        }
        case actions.TEST_RUN_COMPLETE: {
            return {
                ...state,
                testsRunning: false
            };
        }
        default: {
            return state;
        }
    }
};

export default testsReducer;
