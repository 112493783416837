import { all, call, put, select } from 'redux-saga/effects';
import * as actions from '../store/actionsTypes';
import * as browserFeatureDetection from '../testModules/browserFeatureDetection';
import * as audioCapture from '../testModules/audioCapture';
import * as videoCapture from '../testModules/videoCapture';
import * as browserVersion from '../testModules/browserVersion';
import * as screenSize from '../testModules/screenSize';
import * as twilioVideo from '../testModules/twilioVideo';
import * as resultSubmitSagas from './resultSubmitSagas';

const getTestConfig = (state) => {
    return {
        backendRoot: state.configReducer.backendRoot,
        testsToRun: state.configReducer.testsToRun,
        runRecordingTest: state.configReducer.runRecordingTest,
        recordingSystem: state.configReducer.recordingSystem
    };
};


export function *runAllTests () {
    const testConfig = yield select(getTestConfig);
    const testsToRun = testConfig.testsToRun;

    // Build a dictionary of tests and their configurations.
    const configMap = testsToRun.reduce((map, item) => {
        if (typeof(item) === 'string') {
            map[item] = { enabled: true };
        } else if (typeof(item) === 'object' && item?.type) {
            const type = item.type;
            map[type] = {
                ...item,
                enabled: true
            };
        } else {
            throw new Error('Invalid test configuration received');
        }
        return map;
    }, {});

    // Wrapper to only run the test if it's set to run.
    function *runIfRequired (test) {
        const config = configMap[test.TEST_ID];
        if (config?.enabled) {
            yield call(test.runTest, config);
        }
    }

    // Tests run in a set order - this makes it easier to handle any shared dependencies.
    // Current known cases:
    //  * WebRTC devices - safer to run any tests using local media devices in isolation, especially on Safari.
    //  * Bandwidth checks - consider doing these on their own - don't want anything else on the network at the same
    //    time.
    yield put({type: actions.RESET_TESTS, tests: testsToRun});
    yield all([
        runIfRequired(browserFeatureDetection),
        runIfRequired(browserVersion),
        runIfRequired(screenSize),
        runIfRequired(audioCapture)
    ]);
    yield runIfRequired(videoCapture); // Make sure this is running after the audio capture test is done
    yield runIfRequired(twilioVideo); // Make sure we're not conflicting for resources.
    yield call(resultSubmitSagas.submitResult);
    yield put({type: actions.TEST_RUN_COMPLETE});
}
