import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { Provider } from 'react-redux';
import {
    MemoryRouter,
    BrowserRouter,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import './i18n';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Initialize Sentry - as early as possible

Sentry.init({
    dsn: 'https://516e75bc78d64c01984c470dc4cf5c6a@o930653.ingest.sentry.io/4503969715978240',
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.SENTRY_ENVIRONMENT
});
  

/** @module index */

// If we're in dev, show the routes in the URI
const Router = process.env.NODE_ENV !== 'production' ? BrowserRouter : MemoryRouter;

/**
 * Dummy body component
 */
const DummyBody = () => {
    
    return <div></div>;
};


/**
 * Shim component to add Redux and React Router capabilities into the app.
 */
const doRender = () => {
    ReactDOM.render((
        <Provider store={store}>
            <Suspense fallback={<DummyBody />}>
                <Router initialEntries={[window.location]}>
                    <Route path="/:url*" exact strict render={props => <Redirect to={`${props.location.pathname}/`}/>}/>
                    <Switch>
                        <Route path="/">
                            <App />
                        </Route>
                    </Switch>
                </Router>
            </Suspense>
        </Provider>
    ), document.getElementById('root'));
};

if (module.hot) {
    module.hot.accept('./App', doRender);
}

doRender();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
